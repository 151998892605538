export const NuxtLogo = () => import('../../components/NuxtLogo.vue' /* webpackChunkName: "components/nuxt-logo" */).then(c => wrapFunctional(c.default || c))
export const Tutorial = () => import('../../components/Tutorial.vue' /* webpackChunkName: "components/tutorial" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../../components/VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const AppAbout = () => import('../../components/app/About.vue' /* webpackChunkName: "components/app-about" */).then(c => wrapFunctional(c.default || c))
export const AppBanner = () => import('../../components/app/Banner.vue' /* webpackChunkName: "components/app-banner" */).then(c => wrapFunctional(c.default || c))
export const AppCompaniesNews = () => import('../../components/app/CompaniesNews.vue' /* webpackChunkName: "components/app-companies-news" */).then(c => wrapFunctional(c.default || c))
export const AppImagesSection = () => import('../../components/app/ImagesSection.vue' /* webpackChunkName: "components/app-images-section" */).then(c => wrapFunctional(c.default || c))
export const AppImagesSectionOld = () => import('../../components/app/ImagesSectionOld.vue' /* webpackChunkName: "components/app-images-section-old" */).then(c => wrapFunctional(c.default || c))
export const AppSubscribe = () => import('../../components/app/Subscribe.vue' /* webpackChunkName: "components/app-subscribe" */).then(c => wrapFunctional(c.default || c))
export const AppTestimonials = () => import('../../components/app/Testimonials.vue' /* webpackChunkName: "components/app-testimonials" */).then(c => wrapFunctional(c.default || c))
export const AppTestimonialsOld = () => import('../../components/app/TestimonialsOld.vue' /* webpackChunkName: "components/app-testimonials-old" */).then(c => wrapFunctional(c.default || c))
export const AppVideoSection = () => import('../../components/app/VideoSection.vue' /* webpackChunkName: "components/app-video-section" */).then(c => wrapFunctional(c.default || c))
export const AppWhyUs = () => import('../../components/app/WhyUs.vue' /* webpackChunkName: "components/app-why-us" */).then(c => wrapFunctional(c.default || c))
export const UserAdminGeneralNews = () => import('../../components/user-admin/GeneralNews.vue' /* webpackChunkName: "components/user-admin-general-news" */).then(c => wrapFunctional(c.default || c))
export const UserAdminSearch = () => import('../../components/user-admin/Search.vue' /* webpackChunkName: "components/user-admin-search" */).then(c => wrapFunctional(c.default || c))
export const AppIncludesConsultPop = () => import('../../components/app/includes/ConsultPop.vue' /* webpackChunkName: "components/app-includes-consult-pop" */).then(c => wrapFunctional(c.default || c))
export const AppIncludesFooter = () => import('../../components/app/includes/Footer.vue' /* webpackChunkName: "components/app-includes-footer" */).then(c => wrapFunctional(c.default || c))
export const AppIncludesHeader = () => import('../../components/app/includes/Header.vue' /* webpackChunkName: "components/app-includes-header" */).then(c => wrapFunctional(c.default || c))
export const UserAdminAccountMenu = () => import('../../components/user-admin/account/menu.vue' /* webpackChunkName: "components/user-admin-account-menu" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCalendarMiniCalendarCard = () => import('../../components/user-admin/calendar/MiniCalendarCard.vue' /* webpackChunkName: "components/user-admin-calendar-mini-calendar-card" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCalendarModal = () => import('../../components/user-admin/calendar/Modal.vue' /* webpackChunkName: "components/user-admin-calendar-modal" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCompanyInfoCard = () => import('../../components/user-admin/company/InfoCard.vue' /* webpackChunkName: "components/user-admin-company-info-card" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCompanyInfoText = () => import('../../components/user-admin/company/InfoText.vue' /* webpackChunkName: "components/user-admin-company-info-text" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCompanyMenu = () => import('../../components/user-admin/company/Menu.vue' /* webpackChunkName: "components/user-admin-company-menu" */).then(c => wrapFunctional(c.default || c))
export const UserAdminCompanyNews = () => import('../../components/user-admin/company/News.vue' /* webpackChunkName: "components/user-admin-company-news" */).then(c => wrapFunctional(c.default || c))
export const UserAdminIncludesHeader = () => import('../../components/user-admin/includes/Header.vue' /* webpackChunkName: "components/user-admin-includes-header" */).then(c => wrapFunctional(c.default || c))
export const UserAdminIncludesSidebar = () => import('../../components/user-admin/includes/Sidebar.vue' /* webpackChunkName: "components/user-admin-includes-sidebar" */).then(c => wrapFunctional(c.default || c))
export const UserAdminUserDropdownMenu = () => import('../../components/user-admin/user/DropdownMenu.vue' /* webpackChunkName: "components/user-admin-user-dropdown-menu" */).then(c => wrapFunctional(c.default || c))
export const UserAdminUserMenu = () => import('../../components/user-admin/user/Menu.vue' /* webpackChunkName: "components/user-admin-user-menu" */).then(c => wrapFunctional(c.default || c))
export const UserAdminUserNotifications = () => import('../../components/user-admin/user/Notifications.vue' /* webpackChunkName: "components/user-admin-user-notifications" */).then(c => wrapFunctional(c.default || c))
export const GeneralBlogCard = () => import('../../components/general/blog/Card.vue' /* webpackChunkName: "components/general-blog-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsBars = () => import('../../components/general/charts/Bars.vue' /* webpackChunkName: "components/general-charts-bars" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsCurves = () => import('../../components/general/charts/Curves.vue' /* webpackChunkName: "components/general-charts-curves" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsGear = () => import('../../components/general/charts/Gear.vue' /* webpackChunkName: "components/general-charts-gear" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsLines = () => import('../../components/general/charts/Lines.vue' /* webpackChunkName: "components/general-charts-lines" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsMultiPointerGear = () => import('../../components/general/charts/MultiPointerGear.vue' /* webpackChunkName: "components/general-charts-multi-pointer-gear" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsPie = () => import('../../components/general/charts/Pie.vue' /* webpackChunkName: "components/general-charts-pie" */).then(c => wrapFunctional(c.default || c))
export const GeneralChartsDonut = () => import('../../components/general/charts/donut.vue' /* webpackChunkName: "components/general-charts-donut" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanySideMenu = () => import('../../components/general/company/SideMenu.vue' /* webpackChunkName: "components/general-company-side-menu" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyStatsCard = () => import('../../components/general/company/StatsCard.vue' /* webpackChunkName: "components/general-company-stats-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyStockSum = () => import('../../components/general/company/StockSum.vue' /* webpackChunkName: "components/general-company-stock-sum" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTradeViewChart = () => import('../../components/general/company/TradeViewChart.vue' /* webpackChunkName: "components/general-company-trade-view-chart" */).then(c => wrapFunctional(c.default || c))
export const GeneralConsultChatBoxCard = () => import('../../components/general/consultChatBox/card.vue' /* webpackChunkName: "components/general-consult-chat-box-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralNewsColumn = () => import('../../components/general/news/Column.vue' /* webpackChunkName: "components/general-news-column" */).then(c => wrapFunctional(c.default || c))
export const GeneralNewsGrid = () => import('../../components/general/news/Grid.vue' /* webpackChunkName: "components/general-news-grid" */).then(c => wrapFunctional(c.default || c))
export const GeneralSharesCalendar = () => import('../../components/general/shares/Calendar.vue' /* webpackChunkName: "components/general-shares-calendar" */).then(c => wrapFunctional(c.default || c))
export const GeneralSharesMiniCards = () => import('../../components/general/shares/MiniCards.vue' /* webpackChunkName: "components/general-shares-mini-cards" */).then(c => wrapFunctional(c.default || c))
export const GeneralSharesNews = () => import('../../components/general/shares/News.vue' /* webpackChunkName: "components/general-shares-news" */).then(c => wrapFunctional(c.default || c))
export const GeneralSubscribeCard = () => import('../../components/general/subscribe/Card.vue' /* webpackChunkName: "components/general-subscribe-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralSubscribePackages = () => import('../../components/general/subscribe/Packages.vue' /* webpackChunkName: "components/general-subscribe-packages" */).then(c => wrapFunctional(c.default || c))
export const GeneralSubscribePayment = () => import('../../components/general/subscribe/Payment.vue' /* webpackChunkName: "components/general-subscribe-payment" */).then(c => wrapFunctional(c.default || c))
export const GeneralSubscribeInvoice = () => import('../../components/general/subscribe/invoice.vue' /* webpackChunkName: "components/general-subscribe-invoice" */).then(c => wrapFunctional(c.default || c))
export const GeneralSubscribePaymentCard = () => import('../../components/general/subscribe/paymentCard.vue' /* webpackChunkName: "components/general-subscribe-payment-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralTablesBasic = () => import('../../components/general/tables/Basic.vue' /* webpackChunkName: "components/general-tables-basic" */).then(c => wrapFunctional(c.default || c))
export const GeneralTablesFinancialTable = () => import('../../components/general/tables/FinancialTable.vue' /* webpackChunkName: "components/general-tables-financial-table" */).then(c => wrapFunctional(c.default || c))
export const GeneralTablesIndicatorsTable = () => import('../../components/general/tables/IndicatorsTable.vue' /* webpackChunkName: "components/general-tables-indicators-table" */).then(c => wrapFunctional(c.default || c))
export const GeneralTablesSharesTable = () => import('../../components/general/tables/SharesTable.vue' /* webpackChunkName: "components/general-tables-shares-table" */).then(c => wrapFunctional(c.default || c))
export const GeneralTablesTable = () => import('../../components/general/tables/Table.vue' /* webpackChunkName: "components/general-tables-table" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiDatePicker = () => import('../../components/general/ui/DatePicker.vue' /* webpackChunkName: "components/general-ui-date-picker" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiHeaderItems = () => import('../../components/general/ui/HeaderItems.vue' /* webpackChunkName: "components/general-ui-header-items" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiHeaderMobileItems = () => import('../../components/general/ui/HeaderMobileItems.vue' /* webpackChunkName: "components/general-ui-header-mobile-items" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiLanguageMenu = () => import('../../components/general/ui/LanguageMenu.vue' /* webpackChunkName: "components/general-ui-language-menu" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiMainSearch = () => import('../../components/general/ui/MainSearch.vue' /* webpackChunkName: "components/general-ui-main-search" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiMiniCard = () => import('../../components/general/ui/MiniCard.vue' /* webpackChunkName: "components/general-ui-mini-card" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiMobileItems = () => import('../../components/general/ui/MobileItems.vue' /* webpackChunkName: "components/general-ui-mobile-items" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiNoData = () => import('../../components/general/ui/NoData.vue' /* webpackChunkName: "components/general-ui-no-data" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiNoDataSm = () => import('../../components/general/ui/NoDataSm.vue' /* webpackChunkName: "components/general-ui-no-data-sm" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiSliderRange = () => import('../../components/general/ui/SliderRange.vue' /* webpackChunkName: "components/general-ui-slider-range" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiSvgIcon = () => import('../../components/general/ui/SvgIcon.vue' /* webpackChunkName: "components/general-ui-svg-icon" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiUserDropDown = () => import('../../components/general/ui/UserDropDown.vue' /* webpackChunkName: "components/general-ui-user-drop-down" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiUploadImg = () => import('../../components/general/ui/uploadImg.vue' /* webpackChunkName: "components/general-ui-upload-img" */).then(c => wrapFunctional(c.default || c))
export const GeneralUiYearMonthToggle = () => import('../../components/general/ui/yearMonthToggle.vue' /* webpackChunkName: "components/general-ui-year-month-toggle" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserForgetPassword = () => import('../../components/general/user/ForgetPassword.vue' /* webpackChunkName: "components/general-user-forget-password" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserNoAccess = () => import('../../components/general/user/NoAccess.vue' /* webpackChunkName: "components/general-user-no-access" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserPasswordStrength = () => import('../../components/general/user/PasswordStrength.vue' /* webpackChunkName: "components/general-user-password-strength" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserRegister = () => import('../../components/general/user/Register.vue' /* webpackChunkName: "components/general-user-register" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserResetPassword = () => import('../../components/general/user/ResetPassword.vue' /* webpackChunkName: "components/general-user-reset-password" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserSocialLogin = () => import('../../components/general/user/SocialLogin.vue' /* webpackChunkName: "components/general-user-social-login" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserLogin = () => import('../../components/general/user/login.vue' /* webpackChunkName: "components/general-user-login" */).then(c => wrapFunctional(c.default || c))
export const GeneralUserVerification = () => import('../../components/general/user/verification.vue' /* webpackChunkName: "components/general-user-verification" */).then(c => wrapFunctional(c.default || c))
export const GeneralWalletsCreateWallet = () => import('../../components/general/wallets/createWallet.vue' /* webpackChunkName: "components/general-wallets-create-wallet" */).then(c => wrapFunctional(c.default || c))
export const GeneralWalletsManageWallets = () => import('../../components/general/wallets/manageWallets.vue' /* webpackChunkName: "components/general-wallets-manage-wallets" */).then(c => wrapFunctional(c.default || c))
export const GeneralWalletsWalletForm = () => import('../../components/general/wallets/walletForm.vue' /* webpackChunkName: "components/general-wallets-wallet-form" */).then(c => wrapFunctional(c.default || c))
export const UserAdminAccountTabsPassword = () => import('../../components/user-admin/account/tabs/Password.vue' /* webpackChunkName: "components/user-admin-account-tabs-password" */).then(c => wrapFunctional(c.default || c))
export const UserAdminAccountTabsPayments = () => import('../../components/user-admin/account/tabs/Payments.vue' /* webpackChunkName: "components/user-admin-account-tabs-payments" */).then(c => wrapFunctional(c.default || c))
export const UserAdminAccountTabsProfile = () => import('../../components/user-admin/account/tabs/Profile.vue' /* webpackChunkName: "components/user-admin-account-tabs-profile" */).then(c => wrapFunctional(c.default || c))
export const UserAdminAccountTabsSettings = () => import('../../components/user-admin/account/tabs/Settings.vue' /* webpackChunkName: "components/user-admin-account-tabs-settings" */).then(c => wrapFunctional(c.default || c))
export const UserAdminAccountTabsSubscriptions = () => import('../../components/user-admin/account/tabs/Subscriptions.vue' /* webpackChunkName: "components/user-admin-account-tabs-subscriptions" */).then(c => wrapFunctional(c.default || c))
export const UserAdminUserToolbarSidebarItems = () => import('../../components/user-admin/user/toolbar/SidebarItems.vue' /* webpackChunkName: "components/user-admin-user-toolbar-sidebar-items" */).then(c => wrapFunctional(c.default || c))
export const UserAdminUserToolbarHeaderItems = () => import('../../components/user-admin/user/toolbar/headerItems.vue' /* webpackChunkName: "components/user-admin-user-toolbar-header-items" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsCachCharts = () => import('../../components/general/company/tabs/CachCharts.vue' /* webpackChunkName: "components/general-company-tabs-cach-charts" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsComparison = () => import('../../components/general/company/tabs/Comparison.vue' /* webpackChunkName: "components/general-company-tabs-comparison" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsDistributionDates = () => import('../../components/general/company/tabs/DistributionDates.vue' /* webpackChunkName: "components/general-company-tabs-distribution-dates" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsFinancialList = () => import('../../components/general/company/tabs/FinancialList.vue' /* webpackChunkName: "components/general-company-tabs-financial-list" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsIndebtednessCharts = () => import('../../components/general/company/tabs/IndebtednessCharts.vue' /* webpackChunkName: "components/general-company-tabs-indebtedness-charts" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsInvestmentCharts = () => import('../../components/general/company/tabs/InvestmentCharts.vue' /* webpackChunkName: "components/general-company-tabs-investment-charts" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsProfile = () => import('../../components/general/company/tabs/Profile.vue' /* webpackChunkName: "components/general-company-tabs-profile" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsProfitCharts = () => import('../../components/general/company/tabs/ProfitCharts.vue' /* webpackChunkName: "components/general-company-tabs-profit-charts" */).then(c => wrapFunctional(c.default || c))
export const GeneralCompanyTabsQualityCharts = () => import('../../components/general/company/tabs/QualityCharts.vue' /* webpackChunkName: "components/general-company-tabs-quality-charts" */).then(c => wrapFunctional(c.default || c))
export const GeneralComparisonsIndicatorsEfficiency = () => import('../../components/general/comparisons/indicators/Efficiency.vue' /* webpackChunkName: "components/general-comparisons-indicators-efficiency" */).then(c => wrapFunctional(c.default || c))
export const GeneralComparisonsIndicatorsIndebtedness = () => import('../../components/general/comparisons/indicators/Indebtedness.vue' /* webpackChunkName: "components/general-comparisons-indicators-indebtedness" */).then(c => wrapFunctional(c.default || c))
export const GeneralComparisonsIndicatorsInvestments = () => import('../../components/general/comparisons/indicators/Investments.vue' /* webpackChunkName: "components/general-comparisons-indicators-investments" */).then(c => wrapFunctional(c.default || c))
export const GeneralComparisonsIndicatorsLiquidity = () => import('../../components/general/comparisons/indicators/Liquidity.vue' /* webpackChunkName: "components/general-comparisons-indicators-liquidity" */).then(c => wrapFunctional(c.default || c))
export const GeneralComparisonsIndicatorsProfitability = () => import('../../components/general/comparisons/indicators/Profitability.vue' /* webpackChunkName: "components/general-comparisons-indicators-profitability" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}
